import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
import './SongRequestModal.css'

const SongRequestModal = ({ show, sessionId, handleClose, handleRequest }) => {
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        searchSpotify(search);
      } else {
        setResults([])
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleSelectSong = (title, artist, image) => {
    // Assuming `song` has `title` and `artist` properties
    handleRequest(title, artist, image);
    handleClose();
  };
  async function searchSpotify(search) {
    setIsLoading(true);
    try {
      let endpoint = process.env.REACT_APP_APP_BASE_URL + 'Sessions/SearchSpotifyAsync?query=' + search;
      try {
          const response = await axios.get(endpoint);
          setResults(response.data);
          setIsLoading(false);
      } catch (error) {
          setIsLoading(false);
          return null;
      }

    } catch (error) {
      console.error('Failed to fetch from Spotify API:', error);
    }
  };

  return (
    <>
      <Toaster />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request Song</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formSongSearch">
              <Form.Label>Song Search</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search songs"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
                
              {results && results.length > 0 && (
                <ul style={{listStyle:'none', padding:'4px', maxHeight:'50vh',overflowY:'auto', border:'1px solid lightgrey'}}>
                  {results.map((song) => (
                    <li key={song.id} style={{padding:'4px',display:'flex', marginLeft:5}} onClick={() => handleSelectSong(song.name, song.artists[0].name, song.album.images[0]?.url)} className='song-info-container'>
                      <img src={song.album.images[0]?.url} alt={song.album.name} style={{ width: 50, height: 50 }} />
                      <div className='song-info' style={{marginLeft:5}}>
                        <span style={{fontWeight:800}}>{song.name}</span><br />
                        <span>{song.artists.map(artist => artist.name).join(', ')}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SongRequestModal;
