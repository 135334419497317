import React, { useEffect, useState } from 'react';
import './ViewSession.css'
import Navigation from '../../../components/nav/Navigation';
import SongRequestModal from '../../../components/modals/SongRequestModal';
import QRCodeModal from '../../../components/modals/QRCodeModal';
import axios from 'axios';
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css'; // Import the Font Awesome CSS
import { Toaster, toast } from 'react-hot-toast';
import pusher from '../../../pusherConfig';
import { useNavigate } from "react-router-dom";
import { Flipper, Flipped } from 'react-flip-toolkit';
import { Dropdown, Button } from 'react-bootstrap';
import EndSessionModal from '../../../components/modals/EndSessionModal';


const ViewSession = () =>{

  const [sessionData,setSessionData] = useState(null);
  const [creatorUserName,setCreatorUserName] = useState(null);
  const [rows,setRows] = useState(null);
  const [requests, setRequests] = useState([]);
  const { id } = useParams();
  const [isCreator, setIsCreator] = useState(false);
  const [showSongRequestModal, setShowSongRequestModal] = useState(false);
  const [showQRCodeModal, setShowQRCodeModal] = useState(false);
  const [showEndSessionModal, setShowEndSessionModal] = useState(false);
  


  const openSongRequestModal = () => setShowSongRequestModal(true);
  const closeSongRequestModal = () => setShowSongRequestModal(false);

  const openQRCodeModal = () => {
    setShowQRCodeModal(true);
  }
  const closeQRCodeModal = () => setShowQRCodeModal(false);

  const openEndSessionModal = () => setShowEndSessionModal(true);
  const closeEndSessionModal = () => setShowEndSessionModal(false);

  const navigate = useNavigate();
  const goToEditSession = (e) => {
    e.preventDefault();

    navigate('/session/edit/' + sessionData.id);
  }

  useEffect(() => {
      async function fetchData() {
        await getSession(id);
      }
      fetchData();
      
      initPusher();
  }, []);

  useEffect(() => {
    // Check if the logged-in user is the creator of the session
    const userId = localStorage.getItem('userId');
    if (userId && sessionData && sessionData.creatorUserId === parseInt(userId)) {
      setIsCreator(true);
    }
  }, [sessionData]);

  // Rest of your code remains unchanged

  async function getSession(id) {
      // Check if the logged-in user is the creator of the session
      const userId = localStorage.getItem('userId');

      let endpoint = process.env.REACT_APP_APP_BASE_URL + 'Sessions/'+id;

      if(userId == null || userId === undefined || userId === "null") {

      } else {
        endpoint += "?userId=" + userId;
      }
      try {
          const response = await axios.get(endpoint);
          showSession(response.data)
      } catch (error) {
          toast.error(error.response ? error.response.data : "Error getting sessions.");
          return null; // or handle the error in a way that makes sense for your application
      }
  }

  const handleThumbsUpClick = (requestId, currentInteraction, sessionId) => {
    const newInteraction = currentInteraction === 1 ? null : 1;
    handleInteraction(requestId, newInteraction, sessionId);
  };

  const handleThumbsDownClick = (requestId, currentInteraction, sessionId) => {
    const newInteraction = currentInteraction === 0 ? null : 0;
    handleInteraction(requestId, newInteraction, sessionId);
  };

  const handleSongRequest = (title, artist, image) => {
    // Here you can add authentication logic to check the username and password
    // For example, send a request to your server to validate the credentials.
    var endpoint = process.env.REACT_APP_APP_BASE_URL + 'Sessions/RequestSong'
    axios.post(endpoint, {
      requestTitle: title,
      requestAuthor: artist,
      image: image,
      creatorUserId: localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId'),0) : 0,
      sessionId: sessionData.id
    })
    .then(function (response) {
      toast.success("Song Requested Successfully!", {position: "bottom-right"})
    })
    .catch(function (error) {
      toast.error(error.response ? error.response.data : "Error Requesting Song!");
    });
  };
  function initPusher() {
    // Subscribe to a channel
    const channel = pusher.subscribe('session_' + id);

    // Bind to an event
    channel.bind('update', (data) => {
      showSession(data);
    });

    // Cleanup function to unsubscribe and unbind
    return () => {
      channel.unbind('update');
      pusher.unsubscribe('session_' + id);
    };
  }

  const handleInteraction = async (requestId, interaction, sessionId) => {
    const endpoint = `${process.env.REACT_APP_APP_BASE_URL}Interaction/Upsert`; // Adjust if necessary
    const userId = localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId'),0) : 0;

    // Ensure there's a valid session and user ID before proceeding
    if (isNaN(userId) || userId === 0 || !sessionId) {
      toast.error("You must login and select a valid session to interact with requests!");
      return;
    }

  
    try {
      var _interaction_bool = null;

      if(interaction === 1) _interaction_bool = true;
      if(interaction === 0) _interaction_bool = false;

      
      const response = await axios.post(endpoint, {
        userId: userId,
        requestId: requestId,
        userInteraction: _interaction_bool,
        sessionId: sessionId
      });
  
      if (response.status === 200) {
        // Update local state to reflect the new interaction
        const updatedRequests = requests.map(request => {
          if (request.request.id === requestId) {
            return { ...request.request, userInteraction: interaction };
          }
          return request.request;
        });
        setRequests(updatedRequests);
      }
    } catch (error) {
      toast.error("Error updating interaction.");
    }
  };
      

  function showSession(data) {
    setSessionData(data.session);
    setCreatorUserName(data.creatorUserName);
    setRequests(data.requests);
  
    const rows = generateRows(data.requests, localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId'),0) : 0); // Use generateRows to create row elements
    setRows(rows);
  }

  // Adjusted function to generate rows with Flipper and Flipped
  function generateRows(requests, userId) {
    for (let x of requests) {
      x.userInteraction = null;
      for (let y of x.interactions) {
        if (userId === y.userId) {
          x.userInteraction = y.userInteraction;
        }
      }
    }

    if(requests && requests.length > 0) {
      return requests.map((request, index) => (
        <Flipped key={request.request.id} flipId={request.request.id}>
          <tr>
            <td style={{maxWidth: 'max-content', verticalAlign:'middle'}}>
              <span className='upvote-container'>
                <span className={`vote-count-container`}>
                  <span className="vote-count">{Math.max(0, request.upvoteTotal)}</span>
                </span>
                <div className="two-sided-button">
                  <button
                    className={`thumbs-button up ${request.userInteraction === true ? 'active' : ''}`}
                    onClick={() => handleThumbsUpClick(request.request.id, request.userInteraction, request.request.sessionId)}
                  >
                    <FontAwesomeIcon icon={faThumbsUp} />
                  </button>
                  <button
                    className={`thumbs-button down ${request.userInteraction === false ? 'active' : ''}`}
                    onClick={() => handleThumbsDownClick(request.request.id, request.userInteraction, request.request.sessionId)}
                  >
                    <FontAwesomeIcon icon={faThumbsDown} />
                  </button>
                </div>
              </span>
            </td>
            <td style={{minWidth: 'max-content', width:'100%', display:'flex'}}> 
              { request.request.imageUrl && 
                <img src={request.request.imageUrl} alt={request.request.requestTitle} style={{ width: 50, height: 50 }} />
              }
              <div style={{marginLeft:5, textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap', maxWidth:'50vw'}}>
                <span style={{fontWeight: '600'}}> {request.request.requestTitle}</span><br />
                <span>By: {request.request.requestAuthor}</span>
              </div>
              
            </td>
            <td>{request.createdByUserName}</td>
          </tr>
        </Flipped>
      ));
    } else {
      return ([
        <tr style={{float:'left'}}>
          <td>
            No songs have been Requested...
            <Button variant="primary" onClick={openSongRequestModal}>
                Request Song
            </Button>
          </td>
          <td></td>
          <td></td>
        </tr>
      ])
    }
    
  }
  
  if (sessionData === null) {
    // Handle loading state, you can return a loading spinner or any appropriate UI
    return <div>Loading...</div>;
  }
    return (<>
      <div><Toaster/></div>
      <Navigation />
      <div className="session-form space-on-sides-mb" style={{overflowX: 'auto'}}>
      
        <div className='d-flex view-session-form space-on-sides-mb' style={{alignItems: 'flex-start'}}>
          <span className='session-header' style={{textAlign:'left'}}>
            <h2>{sessionData.name}</h2>
            <span>Created By: {creatorUserName}</span>
          </span>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-actions" variant="primary">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {isCreator && (
                <Dropdown.Item onClick={goToEditSession}>Edit Session</Dropdown.Item>
              )}
              {isCreator && (
                <Dropdown.Item onClick={openEndSessionModal}>End Session</Dropdown.Item>
              )}
              <Dropdown.Item onClick={openQRCodeModal}>Share QR Code</Dropdown.Item>
              <Dropdown.Item onClick={openSongRequestModal}>Request Song</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Flipper flipKey={rows.map(row => row.id).join("")}>
          <table className='table'>
            <thead>
              <tr>
                <th className="thumbs-col"style={{maxWidth:'max-content',minWidth:'max-content'}}></th> {/* For thumbs up/down */}
                <th className="title-col">Song</th>
                <th className="created-by-col">Made By</th> 
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </Flipper>

      </div>
      
      <SongRequestModal
        show={showSongRequestModal}
        sessionId={sessionData.id}
        handleClose={closeSongRequestModal}
        handleRequest={handleSongRequest}
      />
      <QRCodeModal
        show={showQRCodeModal}
        sessionId={sessionData.id}
        handleClose={closeQRCodeModal}
      />
      <EndSessionModal
        show={showEndSessionModal}
        sessionId={sessionData.id}
        handleClose={closeEndSessionModal}
      />
    </>);
  };
export default ViewSession;