import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import QRCode from "react-qr-code";
import ReactDOM from "react-dom";

const QRCodeModal = ({ show, sessionId, handleClose}) => {
  

  return (<>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Session QR Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {show && <QRCode value={`crowdq.online/session/${sessionId}`} />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};
export default QRCodeModal;