import React from 'react';
import './Home.css'
import Navigation from '../../components/nav/Navigation';
import { useNavigate } from "react-router-dom";

const Home = () => {

    const navigate = useNavigate();
    const startSession = (e) => {
        e.preventDefault();
    
        navigate('/session/create');
    }

    const joinSession = (e) => {
        e.preventDefault();

        navigate('/session/join');
    }
    return (
        <>
            <Navigation />
            <div className='home-container'>
                <div className="home-form-container">
                    <span><a href="/" onClick={startSession}>Start Session</a></span>
                    <span>or</span>
                    <span><a href="/" onClick={joinSession}>Join Session</a></span>
                </div>
            </div>
        </>
    );
}

export default Home;