import React, { useState, useEffect } from 'react';
import './EditSession.css'
import Navigation from '../../../components/nav/Navigation';
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import { Toaster, toast } from 'react-hot-toast';

const EditSession = () =>{
  const { sessionId } = useParams(); // Retrieve session ID from URL params
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    shortCode: '',
    isPublic: false,
    address: '',
    city: '',
    state: '',
    zipcode: '',
    description: ''
  });

  useEffect(() => {
    // Fetch session details based on session ID
    axios.get(`${process.env.REACT_APP_APP_BASE_URL}Sessions/${sessionId}`)
      .then(response => {
        const sessionData = response.data.session;
        setFormData({
          name: sessionData.name,
          shortCode: sessionData.shortCode,
          isPublic: sessionData.isPublic,
          address: sessionData.address,
          city: sessionData.city,
          state: sessionData.state,
          zipcode: sessionData.zipcode,
          description: sessionData.description
        });
      })
      .catch(error => {
        console.error('Error fetching session details:', error);
      });
  }, [sessionId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform action to update session with formData
    const endpoint = `${process.env.REACT_APP_APP_BASE_URL}Sessions/${sessionId}`;
    axios.put(endpoint, {
      name: formData.name,
      shortCode: formData.shortCode,
      isPublic: formData.isPublic,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      zipcode: formData.zipcode,
      description: formData.description,
      creatorUserId: localStorage.getItem('userId') // Assuming you still need userId
    })
    .then(response => {
      toast.success('Session updated successfully!', {
        duration: 4000,
        position: 'bottom-right',
        // Additional styling and configuration options
      });
      navigate(`/session/${sessionId}`); // Redirect to session details page
    })
    .catch(error => {
      toast.error("Error updating session! Please try again.");
    });
  };

  // Handle file selection
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Create a URL for the selected file for preview
      const imageURL = URL.createObjectURL(e.target.files[0]);
      setSelectedImage(imageURL);
    }
  };

  return (
    <>
      <div><Toaster/></div>
      <Navigation />
      <div className='d-flex session-form-container'>
        <div className="session-form create-form space-on-sides-mb">
          <h2 style={{textAlign: 'left'}}>Edit Session:</h2>
          <div className='d-flex'>
            <div style={{marginTop: '10px'}}>
              <div>
                {/* Image preview or placeholder */}
                <img src={selectedImage || 'path/to/your/default-placeholder.png'} alt="Uploaded" style={{ width: '10vw', height: '10vw', marginBottom: '10px' }} />
              </div>
              <div>
                {/* Hidden file input */}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                  id="fileInput"
                />
                {/* Button to trigger file input */}
                <button onClick={() => document.getElementById('fileInput').click()}>
                  Upload Image
                </button>
              </div>
            </div>
            <form style={{border: '1px solid #333', margin: '10px', padding: '10px', width: '100%'}} onSubmit={handleSubmit}>
              <label>
                Name:
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
              </label>
              <br />
              <label>
                Public:
                <input type="checkbox" name="isPublic" checked={formData.isPublic} onChange={handleChange} />
              </label>
              <br />
              <label>
                Address:
                <input type="text" name="address" value={formData.address} onChange={handleChange} />
              </label>
              <br />
              <label>
                City:
                <input type="text" name="city" value={formData.city} onChange={handleChange} />
              </label>
              <br />
              <label>
                State:
                <input type="text" name="state" value={formData.state} maxLength={2} onChange={handleChange} />
              </label>
              <br />
              <label>
                Zipcode:
                <input type="text" name="zipcode" value={formData.zipcode} onChange={handleChange} />
              </label>
              <br />
              <label>
                Description:
                <textarea name="description" value={formData.description} onChange={handleChange}></textarea>
              </label>
              <br />
              <button type="submit">Update</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSession;
