import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EndSessionModal = ({ show, sessionId, handleClose }) => {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        setIsLoading(true);
        const endpoint = `${process.env.REACT_APP_APP_BASE_URL}Sessions/${sessionId}/end`;

        axios.put(endpoint)
            .then(response => {
                toast.success("Session ended successfully!");
                handleClose(); // Close the modal after successfully ending the session
                navigate('/session/join');
            })
            .catch(error => {
                toast.error("Error ending session. Please try again.");
                console.error('Error ending session:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Toaster />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>End Session</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to end this session?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit} disabled={isLoading}>
                        {isLoading ? 'Ending...' : 'End Session'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EndSessionModal;
