// Navigation.js
import React, { useState, useEffect }  from "react";
import './Navigation.css'
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Update the loggedIn state based on localStorage
    // Convert string to boolean correctly
    const userId = localStorage.getItem('userId');
    const isLoggedIn = userId !== null && userId !== '' && userId !== 'null';

    setLoggedIn(isLoggedIn);
  });

  const handleMenuToggle = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen);
  };
  const navigate = useNavigate();
  
  const home = (e) => {
    e.preventDefault();

    navigate('/home');
  }
  const logout = (e) => {
    e.preventDefault();

    localStorage.setItem('loggedIn',false);
    localStorage.setItem('userId',null);
    navigate('/login');
  }
  const startSession = (e) => {
    e.preventDefault();

    navigate('/session/create');
  }
  const joinSession = (e) => {
    e.preventDefault();

    navigate('/session/join');
  }
  const accountSettings = (e) => {
    e.preventDefault();

    navigate('/account');
  }
  const mySessions = (e) => {
    e.preventDefault();

    navigate('/session/my');
  }
  const createAccount = (e) => {
    e.preventDefault();

    localStorage.setItem('loggedIn',false);
    localStorage.setItem('userId',null);
    navigate('/create');
  }
  const loginRedirect = (e) => {
    e.preventDefault();

    localStorage.setItem('loggedIn',false);
    localStorage.setItem('userId',null);
    navigate('/login');
  }
  return (<>
    <div className="navbar">
      <h3 className="login-header" onClick={home}>CrowdQ</h3>
      <div className={`navbar-links ${menuOpen ? "active" : ""}`}>
          <a href="/" onClick={startSession}>Start Session</a>
          <a href="/" onClick={joinSession}>Join Session</a>
          
          { loggedIn ? <a href="/" onClick={mySessions}>My Sessions</a> : null}
          { loggedIn ? <a href="/" onClick={accountSettings}>Account</a> : null}
          { loggedIn ? <a href="/" onClick={logout}>Logout</a> : null}
          { !loggedIn ? <a href="/" onClick={createAccount}>Create Account</a> : null}
          { !loggedIn ? <a href="/" onClick={loginRedirect}>Login</a> : null}
      </div>
      <div className={`hamburger-menu ${menuOpen ? "open" : "close"}`} onClick={handleMenuToggle}>
        <div className={`bar ${menuOpen ? "change" : ""}`}></div>
        <div className={`bar ${menuOpen ? "change" : ""}`}></div>
        <div className={`bar ${menuOpen ? "change" : ""}`}></div>
      </div>
      
    </div>
    <div className={`hamburger-links ${menuOpen ? "open" : "close"}`}>
      <div className={`hamburger-link ${menuOpen ? "open" : ""}`}><a href="/" onClick={startSession}>Start Session</a></div><br />
      <div className={`hamburger-link ${menuOpen ? "open" : ""}`}><a href="/" onClick={joinSession}>Join Session</a></div><br />
      { loggedIn ? <><div className={`hamburger-link ${menuOpen ? "open" : ""}`}><a href="/" onClick={mySessions}>My Sessions</a></div><br /></> : null}
      { loggedIn ? <><div className={`hamburger-link ${menuOpen ? "open" : ""}`}><a href="/" onClick={accountSettings}>Account</a></div><br /></> : null}
      { loggedIn ? <><div className={`hamburger-link ${menuOpen ? "open" : ""}`}><a href="/" onClick={logout}>Logout</a></div><br /></> : null}
      { !loggedIn ? <><div className={`hamburger-link ${menuOpen ? "open" : ""}`}><a href="/" onClick={createAccount}>Create Account</a></div><br /></> : null}
      { !loggedIn ? <><div className={`hamburger-link ${menuOpen ? "open" : ""}`}><a href="/" onClick={loginRedirect}>Login</a></div><br /></> : null}
    </div>
  </>);
};

export default Navigation;